(function(w) {
    if(w && w.document.documentElement.className.indexOf('fonts-loaded') > -1) {
      return;
    }

    var fontA = new w.FontFaceObserver( 'Proxima Nova', {
    weight: 500
  });
  var fontB = new w.FontFaceObserver( 'Proxima Nova', {
    weight: 700
  });
  var fontC = new w.FontFaceObserver( 'Source Code Pro', {
    weight: 500
  });

  w.Promise
      .all([
        fontA.load(),
        fontB.load(),
        fontC.load()
      ])
      .then(function() {
        w.document.documentElement.className += ' fonts-loaded';
      }).catch(function (e) {
        
      });

})(this);