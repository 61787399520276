(function() {
    'use strict';

    var init, removeBackLinks, buildButton, buildContent, clickAction, calculateOffset, getTransitionEvent, removeFootnoteChild, removeFootnotes, debounce, resizeAction, calculateSpacing, scrollAction, calculateMargins, closestClass, documentAction, actionSetup, footnoteButtonsBuilder, config;

    init = function() {
        var divFootnotes,
            actualFootnotes;

        divFootnotes = config.scope.querySelector(config.divFootnotesQuery);

        if (!divFootnotes) {
            return false;
        }

        actualFootnotes = divFootnotes.querySelectorAll(config.footnotesQuery);

        footnoteButtonsBuilder(actualFootnotes);
        divFootnotes.parentNode.removeChild(divFootnotes);
        actionSetup();
    };

    removeBackLinks = function(footnoteHTML, backlinkID) {
        var regex;
        if (backlinkID.indexOf(' ') >= 0) {
            backlinkID = backlinkID.trim().replace(/\s+/g, '|').replace(/(.*)/g, '($1)');
        }
        regex = new RegExp('(\\s|&nbsp;)*<\\s*a[^#<]*#' + backlinkID + '[^>]*>(.*?)<\\s*/\\s*a>', 'g');
        return footnoteHTML.replace(regex, '').replace('[]', '');
    };

    buildButton = function(refId, id, number, content) {
        return config.fnButtonMarkup.replace(/\{\{FOOTNOTEREFID\}\}/g, refId).replace(/\{\{FOOTNOTEID\}\}/g, id).replace(/\{\{FOOTNOTENUMBER\}\}/g, number).replace(/\{\{FOOTNOTECONTENT\}\}/g, content);
    };

    buildContent = function(id, content) {
        return config.fnContentMarkup.replace(/\{\{FOOTNOTEID\}\}/g, id).replace(/\{\{FOOTNOTECONTENT\}\}/g, content);
    };

    clickAction = function(event) {
        var button,
            content,
            refId,
            footnoteHtml,
            footnote,
            windowHeight;
        button = event.target;
        content = button.getAttribute('data-fn-content');
        refId = button.getAttribute('data-footnote');

        if (button.nextElementSibling) {
            removeFootnotes();
            return;
        }

        removeFootnotes();

        footnoteHtml = buildContent(refId, content);
        button.insertAdjacentHTML('afterend', footnoteHtml);

        footnote = button.nextElementSibling;

        windowHeight = (window.innerHeight > 0) ? window.innerHeight : window.availHeight; 
        calculateOffset(footnote, button);
        calculateSpacing(footnote, calculateMargins(footnote), windowHeight);
        button.classList.add('is-active');
        footnote.classList.add('footnote-is-active');

        if ('ontouchstart' in document.documentElement) {
            document.body.classList.add('footnote-backdrop');
        }
    };

    calculateOffset = function(fn, btn) {
        var tooltip, container, buttonOffset, buttonWidth, containerWidth, containerOffset, wrapperWidth, wrapperMove, wrapperOffset, tooltipWidth, tooltipOffset, windowWidth;

        btn = typeof btn !== 'undefined' ? btn : fn.previousElementSibling;

        buttonOffset = btn.offsetLeft;
        buttonWidth = btn.offsetWidth;
        tooltip = fn.querySelector('.footnote-tooltip');
        tooltipWidth = tooltip.clientWidth;
        container = fn.parentNode;
        containerWidth = container.clientWidth;
        containerOffset = container.offsetLeft;
        wrapperWidth = fn.offsetWidth;
        wrapperMove = -((wrapperWidth / 2) - (containerWidth / 2));

        windowWidth = (window.innerWidth > 0) ? window.innerWidth : window.availWidth;

        if ((containerOffset + wrapperMove) < 0) {

            wrapperMove = (wrapperMove - (containerOffset + wrapperMove));
        } else if ((containerOffset + wrapperMove + wrapperWidth) > windowWidth) {
            wrapperMove = (wrapperMove - (containerOffset + wrapperMove + wrapperWidth - windowWidth));
        }

        fn.style.left = wrapperMove + 'px';
        wrapperOffset = (containerOffset + wrapperMove);
        tooltipOffset = (containerOffset - wrapperOffset + (containerWidth / 2) - (tooltipWidth / 2));
        tooltip.style.left = tooltipOffset + 'px';
    };

    getTransitionEvent = function() {
        var t, el, transitions;
        el = document.createElement('fake');
        transitions = {
            'transition':'transitionend',
            'OTransition':'oTransitionEnd',
            'MozTransition':'transitionend',
            'WebkitTransition':'webkitTransitionEnd'
        };

        for(t in transitions) {
            if ( el.style[t] !== undefined ) {
                return transitions[t];
            }
        }
    };

    removeFootnoteChild = function(element) {
        element.parentNode.removeChild(element);
    };

    removeFootnotes = function() {
        var footnotes, transitionEvent;
        transitionEvent = getTransitionEvent();
        footnotes = document.querySelectorAll('.footnote-is-active');
        if (footnotes.length > 0) {
            Array.prototype.forEach.call(footnotes, function(el) {
                el.previousElementSibling.classList.remove('is-active');
                if (transitionEvent) {
                    el.addEventListener(transitionEvent, removeFootnoteChild.bind(this, el), false);
                } else {
                    removeFootnoteChild(el);
                }
                el.classList.remove('footnote-is-active');
            });

            if (document.body.classList.contains('footnote-backdrop')) {
                document.body.classList.remove('footnote-backdrop');
            }

        }
    };

    debounce = function(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) { func.apply(context, args); }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) { func.apply(context, args); }
        };
    };

    resizeAction = function() {
        var footnotes;

        footnotes = document.querySelectorAll('.footnote-is-active');
        if (footnotes.length > 0) {
            Array.prototype.forEach.call(footnotes, function(el) {
                calculateOffset(el);
                calculateSpacing(el);
            });
        }
    };

    calculateSpacing = function(fn, margins, windowHeight) {
        var boundingClientRect,
            boundingClientHeight,
            boundingClientBottom;

        margins = typeof margins !== 'undefined' ? margins : calculateMargins(fn);
        windowHeight = typeof windowHeight !== 'undefined' ? windowHeight : window.innerHeight;
        windowHeight = (windowHeight > 0) ? windowHeight : window.availHeight;

        boundingClientRect = fn.getBoundingClientRect();
        boundingClientHeight = boundingClientRect.height;
        boundingClientBottom = boundingClientRect.bottom;

        if (boundingClientBottom > (windowHeight - margins[1])) {
            fn.classList.add('footnote-is-top');
        } else if (windowHeight  - (boundingClientHeight + margins[0]) > boundingClientBottom && fn.classList.contains('footnote-is-top')){
            fn.classList.remove('footnote-is-top');
        }
    };

    scrollAction = function() {
        var footnotes;

        footnotes = document.querySelectorAll('.footnote-is-active');
        if (footnotes.length > 0) {
            var windowHeight, margins;
            windowHeight = (window.innerHeight > 0) ? window.innerHeight : window.availHeight; 
            margins = calculateMargins(footnotes[0]);

            Array.prototype.forEach.call(footnotes, function(el) {
                calculateSpacing(el, margins, windowHeight);
            });
        }
    };

    calculateMargins = function(el) {
        var computedStyle = window.getComputedStyle(el, null);
        return [parseFloat(computedStyle.marginTop), parseFloat(computedStyle.marginBottom)];
    };

    closestClass = function(el, tag) {
        do {
            try {
                if (el.classList.contains(tag)) {
                    return el;
                }
            } catch(e) {
                if (e instanceof TypeError) {
                    return null;
                }
            }
        } while (!!(el = el.parentNode));

        return null;
    };

    documentAction = function(event) {
        if (!closestClass(event.target, 'footnote-container')) {
            removeFootnotes();
        }
    };

    actionSetup = function() {
        var buttons;
        buttons = config.scope.querySelectorAll('.footnote-button');
        Array.prototype.forEach.call(buttons, function(el) {
            el.addEventListener('click', clickAction);
        });

        window.addEventListener('resize', debounce(resizeAction, 100));
        window.addEventListener('scroll', debounce(scrollAction, 100));
        document.body.addEventListener('click', documentAction);
        document.body.addEventListener('touchend', documentAction);

    };

    footnoteButtonsBuilder = function(footnotes) {
        Array.prototype.forEach.call(footnotes, function(el, i) {
            var fnContent,
                fnHrefId,
                fnId,
                ref,
                fnRefNumber,
                footnote;
            fnRefNumber = i + 1;
            fnId = el.id;
            fnHrefId = el.querySelector('a[href^=\'#fnref\']').getAttribute('href').substring(1);
            // Removes the hash from the href attribute. I had to appeal to this because there has been some issues parsing IDs with colons on querySelector. Yes, I tried to escape them, but no good.
            fnContent = removeBackLinks(el.innerHTML.trim(), fnHrefId);
            fnContent = fnContent.replace(/"/g, '&quot;').replace(/&lt;/g, '&ltsym;').replace(/&gt;/g, '&gtsym;');
            if (fnContent.indexOf('<') !== 0) {
                fnContent = '<p>' + fnContent + '</p>';
            }

            ref = document.getElementById(fnHrefId);

            footnote = '<div class="footnote-container">' + buildButton(fnHrefId, fnId, fnRefNumber, fnContent) + '</div>';

            ref.insertAdjacentHTML('afterend', footnote);

            ref.parentNode.removeChild(ref);

        });
        return;
    };

    config = {
        scope: document,
        divFootnotesQuery: '.footnotes',
        footnotesQuery: '[id^=\'fn\']',
        fnButtonMarkup: '<button class="footnote-button" id="{{FOOTNOTEREFID}}" data-footnote="{{FOOTNOTEID}}" alt="See Footnote {{FOOTNOTENUMBER}}" rel="footnote" data-fn-number="{{FOOTNOTENUMBER}}" data-fn-content="{{FOOTNOTECONTENT}}"></button>',
        fnContentMarkup: '<div class="littlefoot-footnote" id="{{FOOTNOTEID}}"><div class="footnote-wrapper"><div class="footnote-content">{{FOOTNOTECONTENT}}</div></div><div class="footnote-tooltip" aria-hidden="true"></div>'
    };

    init();
})();