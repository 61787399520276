(function() {

  function getHtmlSource(url, callback, form) {

    var request = new XMLHttpRequest();
    var params = 'url=' + encodeURIComponent(url);

    request.open('GET', '/ajax?' + params, true);

    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    request.send(params);

    request.onload = function() {
      if (this.status >= 200 && this.status !== 404) {
        // Success
        var parser = new DOMParser();
        var response = parser.parseFromString(request.responseText, 'text/html');
        var data = callback(response);
        var disabledInputs = form.querySelectorAll('.action-data-box .disableable');

        if (!data) {
          hasError('url', form);
          return false;
        }
        
        document.getElementById('actionDataTitle').value = data['title'];
        document.getElementById('actionDataDescription').value = data['description'];

        form.setAttribute('data-step', '2');

        Array.prototype.forEach.call(disabledInputs, function(el) {
          el.disabled = false;
        });


      } else {

        hasError('friendship', form);

      }
    };

    request.onerror = function() {
      
      hasError('connection', form);

    }
  };

  function submitEmail(form) {

    var actionUrl = form.querySelector('#linkToAction').value;
    var actionTitle = form.querySelector('#actionDataTitle').value;
    var actionDescription = form.querySelector('#actionDataDescription').value;
    var userName = form.querySelector('#userDataName').value;
    var userEmail = form.querySelector('#userDataEmail').value;
    var userTwitter = form.querySelector('#userDataTwitter').value;
    var button = form.querySelector('#submitActionButton');

    button.disabled = true;
    button.value = 'Loading';
    button.classList.add('button-loading');

    var request = new XMLHttpRequest();
    var params = 'url=' + encodeURIComponent(actionUrl) + '&title=' + encodeURIComponent(actionTitle) + '&description=' + encodeURIComponent(actionDescription) + '&name=' + encodeURIComponent(userName) + '&email=' + encodeURIComponent(userEmail) + '&twitter=' + encodeURIComponent(userTwitter);

    request.open('POST', '/submit?' + params, true);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    request.send(params);

    request.onload = function() {
      var response = request.responseText;

      button.value = 'Submit';
      button.classList.remove('button-loading');

      if (isJSON(response)) {
        // The data is a JSON, therefore there's an invalid input in the form

        response = JSON.parse(response);
        var errorbox = form.querySelector('.error-box');
        for (var property in response) {
          if (response.hasOwnProperty(property)) {

            hasError(property, form);

          }
        } 
      } else {
        if (response === 'Success') {
          form.classList.add('has-success');

        } else {
          // Argh. Something else happened and the email was not sent, but it was definitely in our end.
          console.error(response);
          button.disabled = false;
          hasError('classified', form); 
        }
        
      }
    };

    request.onerror = function() {

      hasError('clueless', form);

      button.disabled = false;
      button.value = 'Submit';
      button.classList.remove('button-loading');

    }

  };

  function hasError(error, form) {
    var selector = '.input-action[name="'+ error + '"]';
    var errorby = '.error-box [data-errorby="' + error + '"]';
    var inputInvalid = form.querySelector(selector);
    var errorBoxItem = form.querySelector(errorby);

    if (inputInvalid) {
      inputInvalid.classList.add('input-invalid');
    };

    if (errorBoxItem) {
      errorBoxItem.classList.add('is-visible');
    };

    if (!form.classList.contains('has-error')) {
      form.classList.add('has-error');
    };

  }

  function isJSON(data) {
    var ret = true;
    try {
      JSON.parse(data);
    } catch(e) {
      ret = false;
    }
    return ret;
  };

  function ajaxLaunchCenterPro(source) {

    try {
      var title = source.querySelector('#action_view h1').innerHTML;
    } catch(e) {
      return false;
    };
    
    var description = source.querySelector('#description p');

    if (description) {
      description = description.innerHTML;
    } else {
      description = '';
    }

    return {title: title, description: description};
  };

  function ajaxWorkflow(source) {

    try {
      var title = source.querySelector('.install-content h1').innerHTML;
    } catch(e) {
      return false;
    };

    var description = source.querySelector('.install-content h2');

    if (description) {
      description = description.innerHTML;
    } else {
      description = '';
    }

    return {title: title, description: description};
  };

  function ajaxDrafts(source) {
    try {
      var title = source.querySelector('#content .box h2').childNodes[2].textContent.replace(/^\s+(.*)\s+$/gm, '$1');
    } catch(e) {
      return false;
    };
    
    var description = source.querySelector('#content .box .description p');

    if (description) {
      description = description.innerHTML;
    } else {
      description = '';
    }
    
    return {title: title, description: description};
  };

  function ajaxOnewriter(source) {
    try {
      var title = source.querySelector('.action-info h2').childNodes[1].textContent.slice(3);
    } catch(e) {
      return false;
    };

    var description = source.querySelector('.action-info .install-action + p');

    if (description) {
      description = description.innerHTML;
    } else {
      description = '';
    }

    return {title: title, description: description};
  };

  function ajaxEditorial(source) {
    try {
      var title = source.querySelector('.banderole').childNodes[2].textContent.replace(/^\s+(.*)\s+$/gm, '$1');
    } catch(e) {
      return false;
    };

    var description = source.querySelector('#main-text p:nth-of-type(3)');

    if (description) {
      description = description.innerHTML.slice(30);
    } else {
      description = '';
    }
    
    return {title: title, description: description};
  };

  function supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  };

  function populateUserFields() {
    if (!supportsLocalStorage() || localStorage['hasUserData'] !== 'true') { return false; }


    document.getElementById('userDataName').value = localStorage.getItem('username');
    document.getElementById('userDataEmail').value = localStorage.getItem('email');
    document.getElementById('userDataTwitter').value = localStorage.getItem('twitter');
  };

  function removeInvalidForms(form) {
    var inputs = form.querySelectorAll('.input-action');
    var errors = form.querySelectorAll('.error-item');

    Array.prototype.forEach.call(inputs, function(el) {
      el.classList.remove('input-invalid');
    });

    Array.prototype.forEach.call(errors, function(el) {
      el.classList.remove('is-visible');
    });

    if (form.classList.contains('has-error')) {
      form.classList.remove('has-error');
    } else if (form.classList.contains('has-success')) {
      form.classList.remove('has-success');
    };

  };

  function ifHasSuccess(form, input) {
    if(form.classList.contains('has-success')) {

      var button = form.querySelector('#submitActionButton');
      var disabledInputs = form.querySelectorAll('.disableable');

      button.disabled = false;
      form.setAttribute('data-step', '0');
      form.classList.remove('has-success');
      input.value = '';
      
      Array.prototype.forEach.call(disabledInputs, function(el) {
        el.disabled = true;
      });

    }
  }

  function init() {
    var actionForm = document.getElementById('submitActionForm');

    // Checks for user information stored in localStorage
    
    if (actionForm) {

      populateUserFields();

      var actionInput = actionForm.querySelector('#linkToAction');

      actionForm.addEventListener('submit', function(e) {

        e.preventDefault();

        if(supportsLocalStorage()) {
          localStorage.setItem('username', document.getElementById('userDataName').value);
          localStorage.setItem('email', document.getElementById('userDataEmail').value);
          localStorage.setItem('twitter', document.getElementById('userDataTwitter').value);
          localStorage.setItem('hasUserData', 'true');
        };

        removeInvalidForms(this);

        submitEmail(this);

      })

      actionInput.addEventListener('input', function(e) {

        var actionValue = this.value,
            callback,
            appSpan,
            disabledInputs;

        ifHasSuccess(actionForm, this);
        removeInvalidForms(actionForm);
        
        if (actionValue.match(/^https?:\/\/(www\.)?(workflow\.is\/workflows\/|launchcenterpro\.com\/|drafts4-actions\.agiletortoise\.com\/\w\/|1writerapp\.com\/action\/|editorial-workflows\.com\/workflow\/).*/gm)) {

          appSpan = actionForm.querySelector('.action-input-appicon');
          disabledInputs = actionForm.querySelectorAll('.user-data-box .disableable');

          // Moves one step: displays user information;
          actionForm.setAttribute('data-step', '1');

          // Enables inputs containing user information;
          Array.prototype.forEach.call(disabledInputs, function(el) {
            el.disabled = false;
          });

          // Verifies the url
          switch (true) {
            case /(^https:\/\/)?(www\.)?workflow\.is\/workflows\/\w+/.test(actionValue):
              callback = ajaxWorkflow;
              appSpan.setAttribute('data-app', 'workflow');
              break;
            case /(https:\/\/)?(www\.)?launchcenterpro\.com\/\w+/.test(actionValue):
              callback = ajaxLaunchCenterPro;
              appSpan.setAttribute('data-app', 'launch-center-pro');
              break;
            case /(http:\/\/)?(www\.)?drafts4-actions\.agiletortoise\.com\/\w\/\w+/.test(actionValue):
              callback = ajaxDrafts;
              appSpan.setAttribute('data-app', 'drafts');
              break;
            case /(http:\/\/)?(www\.)?1writerapp\.com\/action\/\w+/.test(actionValue):
              callback = ajaxOnewriter;
              appSpan.setAttribute('data-app', 'onewriter');
              break;
            case /(http:\/\/)?(www\.)?editorial-workflows\.com\/workflow\/\d+\/.+/.test(actionValue):
              callback = ajaxEditorial;
              appSpan.setAttribute('data-app', 'editorial');
              break;
            default:
              callback = false;
              appSpan.setAttribute('data-app', '');
              break;
          }

          getHtmlSource(actionValue, callback, actionForm);

        } else {

          actionForm.setAttribute('data-step', '0');
          disabledInputs = actionForm.querySelectorAll('.disableable');

          Array.prototype.forEach.call(disabledInputs, function(el) {
            el.disabled = true;
          });
        }
      });

      actionInput.addEventListener('focus', function(e) {
        ifHasSuccess(actionForm, this);
      });

      actionInput.addEventListener('paste', function(e) {
        // Removes focus from input
          window.setTimeout(function() {
            this.blur();
          }.bind(this), 100);
      });

    }

  };

  init();

})();