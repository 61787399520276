(function() {
	'use strict';
	
	var init, cache, events, search, filter, resetFilter, config;

	var input, searchItems, cachedRange, cachedTerms, hiddenItems, query, timer;

	init = function() {
		cache();
	};

	cache = function() {

		function getRange(items) {
			var returnArray = [];
			for (var i = 0; i < items.length; ++i) {
				returnArray.push(i);
			}
			return returnArray;
		}

		function getTerms(items) {
			var returnArray = [];
			for (var i = 0; i < items.length; ++i) {
				returnArray
					.push(items[i]
						.getAttribute(config.dataAttr) // data-tags
							.toLowerCase()
								.split(' '));
			}
			return returnArray;
		}

		input = document.getElementById(config.inputId);

		if(!input) {
			return false;
		}

		searchItems = document.getElementById(config.containerId).children;
		cachedRange = getRange(searchItems);
		cachedTerms = getTerms(searchItems);
		// this.shownItems = this.cachedRange; // Make caching work!
		hiddenItems = []; // Make caching work! (also important for the resetFilter, so don't remove this one)

		events();
	};

	events = function() {
		input.addEventListener('input', search);
	};

	search = function() {
		var inputSearch = this;

		function splitQuery(query) {
			var reQuery = query.replace(/[^\w\s\d]/gi, '').split(' ');
			var retQuery = [];
			reQuery.forEach(function(item) {
				if (item.length > 1) {
					retQuery.push(item);
				}
			});
			return retQuery;
		}

		clearTimeout( timer );

		timer = setTimeout(function() {
			var value = inputSearch.value,
				length = value.length,
				trim = value.trim(),
				q = splitQuery(trim);

			if (length >= 3 && trim !== query) {
				filter(q, cachedRange);
			} else if (length < 3) {
				resetFilter();
			}

			query = trim;

		}, config.delay);
	};

	filter = function(query, array) {
		var returnHide = [];

		array.forEach(function(index, i) {
			if ( query.some(function(item) {

				var re = new RegExp(item, 'i');

				return cachedTerms[index].some(function(tag) {
					return tag.match(re);
				});

			}) ) {
				// returnShow.push(i);
				if (searchItems[i].style.display === 'none') {
					searchItems[i].style.display = '';
				}
			} else {
				returnHide.push(i);
				searchItems[i].style.display = 'none';
			}
		});
		// console.timeEnd("Filtering");
		hiddenItems = returnHide;
	};

	resetFilter = function() {
		hiddenItems.forEach(function(i) {
			searchItems[i].style.display = '';
		});
		hiddenItems = [];
	};

	config = {
		delay: 300,
		containerId: 'filter-container',
		inputId: 'quicksearch',
		dataAttr: 'data-tags'
	};

	init();

})();