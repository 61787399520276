(function() {
	var toggle = document.getElementById('navToggle');
	var activeClass = 'nav-is-active';

	var toggleFunc = function() {
		this.body.classList.toggle(activeClass);
		this.removeEventListener('click', toggleFunc);
	}
	
	toggle.addEventListener('click', function(e) {
		e.stopPropagation();
		e.preventDefault();

		if(document.body.classList.contains(activeClass)){
			toggleFunc.bind(document)();
		} else {
			document.body.classList.toggle(activeClass);
			document.addEventListener('click', toggleFunc);
		}
	});

})();